import './assets/css/bootstrap.min.css'
import './css/App.css'
import './css/responsive.css'
import Global from './components/Global'

function App() {
  return (
      <Global/>
  );
}

export default App;
